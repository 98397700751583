// Bootstrap mixins
@import "./bootstrap/_mixins.scss";

// Reset and dependencies
@import "./bootstrap/_print.scss";

// Core CSS
@import "./bootstrap/_root.scss";
@import "./bootstrap/_reboot.scss";
@import "./bootstrap/_type.scss";
@import "./bootstrap/_images.scss";
@import "./bootstrap/_code.scss";
@import "./bootstrap/_grid.scss";
@import "./bootstrap/_tables.scss";
@import "./bootstrap/_forms.scss";
@import "./bootstrap/_buttons.scss";

// Components
@import "./bootstrap/_transitions.scss";
@import "./bootstrap/_dropdown.scss";
@import "./bootstrap/_button-group.scss";
@import "./bootstrap/_input-group.scss";
@import "./bootstrap/_custom-forms.scss";
@import "./bootstrap/_nav.scss";
@import "./bootstrap/_navbar.scss";
@import "./bootstrap/_card.scss";
@import "./bootstrap/_breadcrumb.scss";
@import "./bootstrap/_pagination.scss";
@import "./bootstrap/_badge.scss";
@import "./bootstrap/_jumbotron.scss";
@import "./bootstrap/_alert.scss";
@import "./bootstrap/_progress.scss";
@import "./bootstrap/_media.scss";
@import "./bootstrap/_list-group.scss";
@import "./bootstrap/_close.scss";

// Components w/ JavaScript
@import "./bootstrap/_modal.scss";
@import "./bootstrap/_tooltip.scss";
@import "./bootstrap/_popover.scss";
@import "./bootstrap/_carousel.scss";

// Utility classes
@import "./bootstrap/_utilities.scss";

// Extended Bootstrap components
@import "./custom/type-custom.scss";
@import "./custom/alerts-custom.scss";
@import "./custom/buttons-custom.scss";
@import "./custom/nav-heading.scss";
@import "./custom/navbar-utilities.scss";

// Custom theme components
//@import "./custom/icons.scss";
@import "./custom/text-inherit.scss";
@import "./custom/nav-bordered.scss";
@import "./custom/buttons-radius.scss";
@import "./custom/container-custom.scss";
@import "./custom/block.scss";
@import "./custom/navbar-transparent.scss";
@import "./custom/carousel-light.scss";
@import "./custom/callouts.scss";
@import "./custom/featured-list.scss";
@import "./custom/zoom.scss";
@import "./custom/text-ribbon.scss";
@import "./custom/iconlist.scss";
@import "./custom/cards-bold.scss";
@import "./custom/statcard.scss";
@import "./custom/pull-quote.scss";
@import "./custom/stage.scss";

//
// Custom styles
//
html {
  // set optimal base size
  font-size: $font-size-root;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  &.text-uppercase {
    letter-spacing: .15em;
  }
  small,
  .small {
    font-weight: inherit;
  }
}

h6, .h6 {
  margin-bottom: ($spacer-y * .25);
  color: lighten($gray-500, 40%);
  text-transform: uppercase;
}

strong {
  font-weight: bold;
}
